<template>
  <div id="app">
    <router-view />
    <select-lang v-model="langView"></select-lang>
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { SelectLang } from "./components/select"
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
import { vConsoleWidget } from "@/utils/index"
export default {
  components: {
    PopupLoading,
    SelectLang
  },
  computed: {
    ...mapState(["loading", "isLangView"]),
    langView: {
      get() {
        return this.isLangView
      },
      set(val) {
        console.log("langview==", val)
        this.$store.commit("isLangView", val)
      }
    }
  },
  methods: {},
  created() {
    const { vc } = this.$route.query
    if (vc === "true") vConsoleWidget()
  }
}
</script>

<style lang="less">
html,
body {
  // background: #f0f1f3;
  padding: 0;
  margin: 0;
  height: 100%;
  color: #333333;
  font-family: "Montserrat-Medium" !important;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background: #e0e722;
  font-size: 32px;
  border-radius: 20px;
}
.w-full {
  width: 100%;
}
.home-logo {
  width: 144px;
  //   height: 160px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 120%;
  }
}
@font-face {
  font-family: "DIN Condensed Bold"; //自定义字体名称
  src: url("fonts/DIN\ Condensed\ Bold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium"; //自定义字体名称
  src: url("fonts/Montserrat-Medium.ttf"); //字体包路径
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold"; //自定义字体名称
  src: url("fonts/Montserrat-SemiBold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
</style>
