<template>
  <div class="content">
    <div class="home-head">
      <div class="home-logo">
        <img src="https://m.chargenet.ee/content/logo.png" alt="" />
      </div>
      <span class="lang" @click="showLangSelect">{{
        locale && locale.label
      }}</span>
    </div>
    <div class="home-body">
      <img
        class="imgmain"
        src="https://m.chargenet.ee/content/introduction1.png"
        alt=""
      />
      <h2 class="downloadapp">{{ i18n.downloadapp }}：</h2>
      <div class="icondark">
        <img
          src="../assets/imgs/appstore_dark.png"
          alt=""
          @click="onAppStore"
        />
        <img
          src="../assets/imgs/googleplay_dark.png"
          alt=""
          @click="onGooglePlay"
        />
      </div>
      <div
        v-if="qrcode"
        class="next"
        @click="goRouter(`/loginborrow?qrcode=${qrcode}`)"
      >
        <div class="text">{{ i18n.rentwithoutapp }}</div>
        <img src="../assets/imgs/icon_next_circle.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import { PopupWarp } from '../components/popup'
// import { MenuSide } from '../components/menu'
import { mapState, mapGetters } from "vuex"
import { QR_CODE, ZH_CODE } from "../apis/cabinet"
import { CREATE_VIP_ORDER, BALANCE_TO_RENT } from "../apis/payment"
import { USER_INFO, OEDER_COUNT } from "../apis/user"
export default {
  components: {},
  watch: {},
  computed: {
    ...mapState([
      "qrcode",
      "zhcode",
      "cabinet",
      "browser",
      "user",
      "system",
      "lang",
      "headLogo"
    ]),
    ...mapGetters(["locale"]),
    i18n() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    title() {
      return process.env.VUE_APP_NAME
    },
    code() {
      return this.qrcode || this.zhcode
    },
    scanUrl() {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    shopDesc() {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    amount() {
      if (this.cabinet) {
        return this.cabinet.yajin
        // return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    },
    isView() {
      return this.isScan && this.cabinet && !this.isBtns
    }
  },
  data() {
    return {
      isScan: false,
      isBtns: false,
      inputCodeShow: false,
      mainHeight: 400,
      ua: navigator.userAgent.toLowerCase(),
      markerList: [],
      oldMarkerList: [],
      cabinetCode: "",
      latitude: "113.788876", // 113.788876
      longitude: "22.703407", // 22.703407
      userAgreement: false,
      privacyAgreement: false
      // payTypeShow: false,
      // payTypeValue: 1
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    goRouter(path) {
      this.$router.push(path)
    },
    onDownload() {
      let downloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        downloadUrl = process.env.VUE_APP_APP_STORE
      }
      if (downloadUrl) {
        window.open(downloadUrl)
      } else {
        this.$toast(this.i18nMsg.appoffline)
      }
    },
    onAppStore() {
      console.log("11111")
      const Url = process.env.VUE_APP_APP_STORE
      if (!Url) return this.$toast(this.i18nMsg.appoffline)
      window.open(Url)
    },
    onGooglePlay() {
      console.log("22222")
      const Url = process.env.VUE_APP_GOOGLE_PLAY
      if (!Url) return this.$toast(this.i18nMsg.appoffline)
      window.open(Url)
    },
    onBack() {
      this.isScan = false
    },
    codeShowClose() {
      this.inputCodeShow = false
      this.cabinetCode = ""
    },
    onScan(isclick) {
      // if (!this.scanUrl && !isclick) return
      let url = this.scanUrl
      if (isclick && this.cabinetCode) {
        url = `${QR_CODE}?id=${this.cabinetCode}`
        this.$store.commit("qrcode", this.cabinetCode)
      } else if (!this.scanUrl && !isclick) {
        return
      }
      this.inputCodeShow = false
      this.cabinetCode = ""
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              // cancelText: this.i18nMsg.cancel,
              showCancel: false
            })
              .then(() => {
                // this.$router.push('/order')
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.isScan = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18n.confirm,
            content: error,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
          // this.$toast(error)
        }
      )
    },
    onSubmit() {
      if (!this.userAgreement || !this.privacyAgreement) {
        this.$toast("Please review and agree to the relevant agreement below")
        return
      }
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.amount,
          payType: "YJ",
          cabinetID: this.cabinet.cabinetID
        }
      })
    },
    onBorrow() {
      this.$loading(true)
      this.$post(
        `${BALANCE_TO_RENT}?qrcode=${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onVIP() {
      this.$loading(true)
      this.$post(
        `${CREATE_VIP_ORDER}/${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          // console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          // console.log(error)
          this.$toast(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              // confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.userinfo_fail,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
          console.log(error)
        }
      )
    },
    onCode() {
      if (this.user) {
        this.isBtns = false
        this.loadUser()
        this.onScan()
      } else {
        this.$router.push("/login")
      }
    },
    closeTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "hidden"
      document
        .getElementsByTagName("body")[0]
        .addEventListener("touchmove", this.handler, { passive: false }) // 阻止默认事件
    },
    openTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "auto"
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("touchmove", this.handler, { passive: false }) // 打开默认事件
    },
    handler(e) {
      e.preventDefault()
    },
    getOrderCount() {
      this.$get(
        OEDER_COUNT,
        {},
        (resp) => {
          if (resp.noMoney) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.unpaid_order,
              confirmText: this.i18nMsg.go_pay,
              showCancel: false
            }).then(() => {
              this.$router.push("/order")
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    // 頭部主頁圖標按鈕
    goHome() {
      this.$store.commit("qrcode", "")
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created() {
    // this.onDownload()
    // console.log('url: ', window.location.href)
    // let qrCode = this.qrcode || this.$route.query.qrcode
    // console.log('qrCode', qrCode)
    // if (qrCode) {
    //   this.$store.commit('qrcode', qrCode)
    //   if (this.user) {
    //     this.onCode()
    //   }
    // }
    // if (this.user) {
    //   this.getOrderCount()
    // }
  },
  mounted() {}
}
</script>

<style lang="less">
.lang {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    margin-left: 10px;
    width: 0;
    border: 10px solid;
    border-color: #000 transparent transparent transparent;
  }
}

.content {
  height: 100vh;
  background: #fff;
}

.home-head {
  width: 100%;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin: 20px 0 0;
  padding: 46px 60px 26px;

  &-title {
    p {
      text-align: end;
      margin: 10px 0;
    }

    &-small {
      font-family: "Montserrat-Medium";
      font-size: 24px;
      color: #171917;
    }

    &-big {
      font-family: "Montserrat-SemiBold";
      font-size: 36px;
      font-weight: 500;
      color: #000;
    }
  }
}

.home-body {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  .imgmain {
    width: 30%;
    display: block;
    margin: 20px auto;
  }
  .downloadapp {
    width: 90%;
    margin: 20px auto;
  }
  .next {
    width: 62%;
    font-size: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e0e722;
    background: #000;
    border-radius: 20px;
    margin: 30px auto 0;
    padding: 20px 0;

    img {
      width: 44px;
      height: 44px;
      margin-left: 20px;
      transform: rotateZ(180deg);
      border-radius: 100%;
      border: solid 1px #fff;
    }
  }

  .icondark {
    img {
      display: block;
      width: 62%;
      margin: 10px auto;
    }
  }
}
</style>
