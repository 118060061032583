
import { LOGIN_APPLE } from "../apis/user"

export default {
  methods: {
    loginByApple(token, call) {
      this.$loading(true)
      this.$post(
        LOGIN_APPLE,
        {
          token: token
        },
        (resp) => {
          this.$loading(false)
          if (resp.token) {
            this.$store.commit("token", resp.token)
            this.$store.commit("loginMode", "Apple")
            this.$emit("success")
            call && call()
          } else {
            this.$toast("登录失败")
          }
        },
        (error) => {
          console.log(error)
          this.$loading(false)
          this.$toast("登录失败")
        }
      )
    }
  }
}
