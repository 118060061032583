<template>
  <div>
    <popup-warp :value="value" @input="onInput">
      <!-- <div class="select-lang-top">
        <span @click="onInput(false)">{{ $t("msg.cancel") }}</span>
        <span @click="onSelect">{{ $t("msg.confirm") }}</span>
      </div> -->
      <!-- <select-column @select="isSelect" :values="localeArr"></select-column> -->
      <Picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        :confirm-button-text="$t('msg.confirm')"
        :cancel-button-text="$t('msg.cancel')"
      />
    </popup-warp>
  </div>
</template>

<script>
import { PopupWarp } from "../popup"
// import SelectColumn from "./column"
import { mapState } from "vuex"
import { Picker } from "vant"
export default {
  components: {
    Picker,
    PopupWarp
    // SelectColumn
  },
  computed: {
    ...mapState(["localeArr"]),
    columns() {
      return this.localeArr.map((item) => {
        return { ...item, text: item.label }
      })
    }
  },
  props: {
    value: {
      tepe: Boolean,
      default: false
    }
  },
  data() {
    return {
      tempVal: ""
    }
  },
  methods: {
    onInput(val) {
      console.log(val)
      this.$emit("input", val)
    },
    onSelect() {
      this.$i18n.locale = this.tempVal.value
      this.$store.commit("lang", this.tempVal.value)
      this.$emit("select", this.tempVal.value)
      this.$emit("input", false)
    },
    isSelect(val) {
      this.tempVal = val
    },

    onConfirm(value, index) {
      this.isSelect(value)
      this.onSelect()
    },
    onCancel() {
      this.onInput(false)
    }
  }
}
</script>

<style lang="less">
.select-lang-top {
  color: #00ce70;
  font-size: 32px;
  line-height: 80px;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
}
</style>
