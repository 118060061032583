<template>
  <div>
    <div class="home-head">
      <img
        class="home-logo"
        src="https://m.chargenet.ee/content/logo.png"
        alt=""
      />
      <span class="lang" @click="showLangSelect">{{
        locale && locale.label
      }}</span>
    </div>
    <div class="home-body">
      <div v-if="!user" class="title">{{ i18n.powerbanktitle }}</div>
      <!-- <div v-if="!user" class="logingroup">
        <LoginApple v-if="system === 'ios' && !user" @success="loadUser(true)">
          <button class="loginbtn" id="sign-in-with-apple-button">
            <img src="../../assets/imgs/icon_applepay.png" alt="">
            <span>{{ i18n.Apple }}</span>
          </button>
        </LoginApple>
        <button class="loginbtn" @click="loginOptionShow = true">
          <img
            :src="require('../../assets/imgs/icon_phone_selected.png')"
            alt=""
          />
          <span>{{ i18n.phone }}</span>
        </button>
        <div class="options" @click="loginOptionShow = true">
          {{ i18n.otheropt }}
        </div>
      </div> -->
      <button v-if="user" class="loginbtn" @click="goOrder">
        <img
          :src="
            loginMode === 'Google'
              ? require('../../assets/imgs/icon_googlepay.png')
              : loginMode === 'Apple'
              ? require('../../assets/imgs/icon_applepay.png')
              : loginMode === 'Facebook'
              ? require('../../assets/imgs/icon_facebook_unselected.png')
              : require('../../assets/imgs/icon_phone_selected.png')
          "
          alt=""
        />
        <span>{{ user && user.pname }}</span>
      </button>
      <div class="logoutoptions" v-if="user" @click="onLogout">
        {{ i18n.logout }}
      </div>
      <!-- <div class="normaltitle">{{ i18n.selectmethod }}</div> -->
      <div v-if="payModeList.length > 0" class="paymethod">
        <template v-for="item in payModeList">
          <div
            class="paymethod-item"
            :class="{
              select: payMode && payMode.pPaymentType === item.pPaymentType
            }"
            v-if="
              item.phone === 'all' ||
              item.phone === system ||
              item.phone === browser ||
              (item.phone === 'phone' && system !== '')
            "
            :key="item.pId"
            @click="payMode = item"
          >
            <img
              :src="
                item.pPaymentType === 'GOOGLE_PAY' ||
                item.pPaymentType === 'STRIPE_GOOGLE'
                  ? require('../../assets/imgs/icon_googlepay.png')
                  : item.pPaymentType === 'APPLE_PAY' ||
                    item.pPaymentType === 'STRIPE_APPLE'
                  ? require('../../assets/imgs/icon_applepay.png')
                  : require('../../assets/imgs/icon_card_Unselected.png')
              "
              alt=""
            />
            <span>{{ item.label }}</span>
          </div>
        </template>
      </div>
      <div v-else-if="!user || !cabinet" class="paymethod">
        <div
          class="paymethod-item"
          :class="{ select: payMode && payMode.pPaymentType === 'STRIPE_CARD' }"
          @click="payMode = { pPaymentType: 'STRIPE_CARD' }"
        >
          <img src="../../assets/imgs/icon_card_Unselected.png" alt="" />
          <span>Credit Card</span>
        </div>
        <!-- <div class="paymethod-item" :class="{ 'select': payMode && payMode.pPaymentType === 'STRIPE_GOOGLE' }"
          @click="payMode = { pPaymentType: 'STRIPE_GOOGLE' }">
          <img src="../../assets/imgs/icon_googlepay.png" alt="">
          <span>Google Pay</span>
        </div> -->
      </div>
      <!-- <div class="normaltitle">{{ i18n.paymentdetail }}</div> -->
      <div class="paymethod-detail">
        <div class="placeinput">
          <img class="preicon" src="../../assets/imgs/icon_pay.png" alt="" />
          <input
            type="text"
            readonly
            :placeholder="`${$t('mine.deposit')} ${
              (cabinet && cabinet.yajin) || 25
            } ${currency}`"
          />
          <img
            class="afticon"
            src="../../assets/imgs/icon_detail_green.png"
            alt=""
            @click="preAuthorShow = true"
          />
        </div>
        <!-- <div v-if="(payMode && payMode.pPaymentType === 'STRIPE_CARD' && !token) || !cabinet" class="placeinput mt-4">
          <img class="preicon" src="../../assets/imgs/icon_card_Unselected.png" alt="">
          <input type="text" v-model="cardNum" placeholder="Card number">
          <span class="cvc">MM / YY CVC</span>
        </div>
        <div v-if="payMode && payMode.pPaymentType === 'STRIPE_CARD' && token && cabinet" class="placeinput mt-4">
          <div class="w-full" id="credit-card-element"></div>
        </div> -->
      </div>
      <div class="shopdetail">
        <img src="https://m.chargenet.ee/content/introduction1.png" alt="" />
        <div v-if="cabinet" class="centermsg">
          <p class="top">
            {{ currency
            }}{{ cabinet && cabinet.jifei && cabinet.jifei.toFixed(2) }}
            {{ i18n.per_hour }}
          </p>
          <p class="bottom">
            {{ currency }}{{ cabinet.fengding }} {{ i18n.per_day }}
          </p>
        </div>
        <div v-else class="centermsg">
          <p class="top">
            {{ currency }} {{ cabinet_init.jifei }} {{ i18n.per_hour }}
          </p>
          <p class="bottom">
            {{ currency }} {{ cabinet_init.fengding }} {{ i18n.per_day }}
          </p>
        </div>
        <div class="pricing" @click="showRentCostPop">
          {{ i18n.pricing }} <img src="../../assets/imgs/icon_morearrow.png" />
        </div>
      </div>
      <!-- <div class="normaltitle">{{ i18n.couponcode }}</div> -->
      <div class="coupon-code">
        <div class="placeinput">
          <img
            class="preicon"
            src="../../assets/imgs/icon_couponcode.png"
            alt=""
          />
          <input
            type="text"
            v-model="couponCode"
            placeholder="Enter promo code"
          />
        </div>
      </div>
      <div class="rentbtn" @click="onRent">
        {{ !user ? i18n.sign_in_to_rent : i18n.rent }}
      </div>
      <div class="agreen">
        {{ i18n.tips }}
        <a
          @click="
            viewAgreen('https://m.chargenet.ee/content/privacy_policy.html')
          "
          >{{ i18n.privacy }}</a
        >
        {{ i18n.and }}
        <a
          @click="
            viewAgreen('https://m.chargenet.ee/content/user_agreement.html')
          "
          >{{ i18n.term }}</a
        >
      </div>
    </div>
    <div v-if="loginOptionShow" class="mask" @click="loginOptionShow = false">
      <div class="mask-main" @click.stop>
        <div class="appleline"></div>
        <!-- <div class="loginoptions">
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'phone' }" @click="currentLoginMode = 'phone'">
            <img src="../../assets/imgs/icon_phone_selected.png" alt="">
            <span>{{ i18n.phone }}</span>
          </div>
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'Community' }" @click="currentLoginMode = 'Community'">
            <span>{{ i18n.otheropt }}</span>
          </div>
        </div> -->
        <div class="phonelogin">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phone"
            :placeholder="i18n.phonenumber"
          />
          <img
            src="../../assets/imgs/icon_next_circle.png"
            alt=""
            @click="onSendCode"
          />
        </div>
        <!-- <div class="phonelogin">
          <input type="number" v-model="code" :placeholder="i18n.captcha_code">
          <img class="captcha" :src="captchaUrl" alt="" @click="onCaptcha">
        </div> -->
        <LoginApple v-if="system === 'ios' && !user" @success="loadUser(true)">
          <button
            class="loginbtn apple-login-btn"
            id="sign-in-with-apple-button"
          >
            <img src="../../assets/imgs/icon_applepay.png" alt="" />
            <span>{{ i18n.Apple }}</span>
          </button>
        </LoginApple>
        <LoginFacebook @success="loadUser(true)">
          <div class="facebookbtn">
            <img src="../../assets/imgs/icon_facebook.png" alt="" />
            <span>{{ i18n.loginfb }}</span>
          </div>
        </LoginFacebook>
        <LoginGoogle @success="loadUser(true)" class="mb-8 minheight-5">
          <div class="buttonDiv" id="buttonDiv"></div>
          <div class="googlewarp">
            <img src="../../assets/imgs/icon_google.png" alt="" />
            <span>{{ i18n.logingg }}</span>
          </div>
          <!-- <button class="goolebtn">
            <img src="../../assets/imgs/icon_google.png" alt="">
            <span>{{ i18n.Google }}</span>
          </button> -->
        </LoginGoogle>
        <div class="agreen">
          {{ i18n.tips }}
          <a
            @click="
              viewAgreen('https://m.chargenet.ee/content/privacy_policy.html')
            "
            >{{ i18n.privacy }}</a
          >
          {{ i18n.and }}
          <a
            @click="
              viewAgreen('https://m.chargenet.ee/content/user_agreement.html')
            "
            >{{ i18n.term }}</a
          >
        </div>
      </div>
    </div>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onCreditcard">
          {{ i18nConfirm.title }}
        </div>
      </div>
    </popup-warp>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <PreAuthorTip
      v-if="preAuthorShow"
      @close="(res) => (preAuthorShow = res)"
    ></PreAuthorTip>
    <RentCostTip
      v-if="rentCostShow"
      :jifei="(cabinet && cabinet.jifei) || cabinet_init.jifei"
      :jifeiDanwei="
        (cabinet && cabinet.jifeiDanwei) || cabinet_init.jifeiDanwei
      "
      :fengding="(cabinet && cabinet.fengding) || cabinet_init.fengding"
      :yajin="(cabinet && cabinet.yajin) || cabinet_init.yajin"
      :cyajin="(cabinet && cabinet.yajin) || cabinet_init.cyajin"
      :currency="currency"
      :currencyName="currencyName"
      @close="(res) => (rentCostShow = res)"
    ></RentCostTip>
    <LoginCode
      v-if="loginCodeShow"
      @close="(res) => (loginCodeShow = res)"
      @submit="onLogin"
    ></LoginCode>
  </div>
</template>

<script>
import md5 from "js-md5"
import { mapState, mapGetters } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import stripeMixin from "../../mixins/stripe"
import loginMixin from "../../mixins/login"
import { generateUUID } from "../../utils/index"
import { baseURL } from "../../utils/http"
import { SelectArea } from "../../components/select"
import PreAuthorTip from "./component/preauthortip.vue"
import RentCostTip from "./component/rentcosttip.vue"
import LoginCode from "./component/logincode.vue"
import {
  LoginApple,
  LoginGoogle,
  LoginFacebook
} from "../../components/login/index"
import { PopupWarp } from "../../components/popup"
import { USER_INFO, LOGIN_CODE, SEND_CODE, USER_CAPTCHA } from "../../apis/user"
import { QR_CODE, ZH_CODE } from "../../apis/cabinet"
import {
  PAYMODE,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREPAY_SPTOKEN,
  COUPON_SEND,
  STRIPE_PREAUTH_AUTO_CONFIRM,
  NEOPAY_RECHARGE
} from "../../apis/payment"
export default {
  mixins: [stripeMixin, loginMixin],
  components: {
    SelectArea,
    PreAuthorTip,
    RentCostTip,
    LoginCode,
    LoginGoogle,
    LoginFacebook,
    LoginApple,
    PopupWarp
  },
  watch: {
    "payMode.pPaymentType": {
      handler(val, oldVal) {
        console.log("payMode", val)
        if (
          val === "APPLE_PAY" ||
          val === "STRIPE_APPLE" ||
          val === "STRIPE_GOOGLE" ||
          val === "GOOGLE_PAY"
        ) {
          if (!this.stripePayRequest && this.cabinet) {
            // this.$loading(true)
            // this.getStripeToken()
          }
        }
      },
      immediate: true, // 立即执行
      deep: true // 深度监听复杂类型内变化
    },
    cabinet(val) {
      if (val) {
        console.log("设备信息", val)
        this.getPayMode()
      }
    }
  },
  computed: {
    ...mapState([
      "browser",
      "user",
      "token",
      "cabinet",
      "system",
      "qrcode",
      "agentId",
      "loginMode"
    ]),
    ...mapGetters(["locale"]),
    scanUrl() {
      if (this.qrcode || this.$route.query.qrcode) {
        return `${QR_CODE}?id=${this.qrcode || this.$route.query.qrcode}`
      }
      if (this.zhcode || this.$route.query.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    i18n() {
      return this.$t("loginborrow")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    i18nConfirm() {
      return this.$t("confirm")
    },
    currency() {
      return (
        (this.cabinet && this.cabinet.currency) ||
        (this.user && this.user.pcurrency) ||
        "EUR"
      )
    },
    currencyName() {
      return (
        (this.cabinet && this.cabinet.currencyName) ||
        (this.user && this.user.pcurrencyName) ||
        "€"
      )
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "372",
      captchaUrl: "",
      code: "",
      uuid: "",
      phone: "",
      cardNum: "",
      couponCode: "",
      preAuthorShow: false,
      loginOptionShow: false,
      rentCostShow: false,
      loginCodeShow: false,
      isCreditcard: false,
      currentLoginMode: "phone",
      payModeList: [],
      payMode: {
        pPaymentType: "STRIPE_CARD"
      },
      payModeDefaultList: [
        {
          phone: "all",
          label: "Credit Card",
          pPaymentType: "STRIPE_CARD"
        },
        {
          phone: "android",
          label: "Google Pay",
          pPaymentType: "STRIPE_GOOGLE"
        },
        {
          phone: "ios",
          label: "Apple Pay",
          pPaymentType: "STRIPE_APPLE"
        }
      ],
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      stripePayRequest: null,
      creditcard: null,
      clientSecret: null,
      creditOrder: null,
      cabinet_init: {
        jifei: 1.89,
        fengding: 5.99,
        yajin: 25,
        cyajin: 1,
        jifeiDanwei: 60
      }
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    goOrder() {
      this.$router.push("/order")
    },
    onSelct(val) {
      this.areaCode = val
    },
    showRentCostPop() {
      this.rentCostShow = true
    },
    viewAgreen(url) {
      window.open(url)
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogout() {
      console.log("退出==")
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.msg_logout,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.$store.commit("user", "")
        this.$store.commit("token", "")
        this.$store.commit("appleIdToken", "")
        // this.$store.commit('qrcode', '')
        this.payModeList = this.payModeDefaultList
      })
    },
    onRent() {
      console.log(this.cabinet)
      if (this.user) {
        if (
          this.cabinet &&
          this.cabinet.zujieNum &&
          this.cabinet.zujieNum > 0
        ) {
          this.$toast(this.i18nMsg.exceedorder)
          return
        }
        this.getCouponCode()
      } else {
        this.loginOptionShow = true
      }
    },
    onPay() {
      if (
        this.payMode.pPaymentType === "APPLE_PAY" ||
        this.payMode.pPaymentType === "STRIPE_APPLE" ||
        this.payMode.pPaymentType === "STRIPE_GOOGLE" ||
        this.payMode.pPaymentType === "GOOGLE_PAY"
      ) {
        // this.stripePayRequest.show()
        this.onCreditcard(this.payMode.publicKey)
      } else if (this.payMode.pPaymentType === "STRIPE_CARD") {
        this.onCreditcard(this.payMode.publicKey)
      } else if (this.payMode.pPaymentType === "NeoPay") {
        this.onNeoPay()
      }
    },
    onScan() {
      let url = this.scanUrl
      this.inputCodeShow = false
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                // this.$store.commit('qrcode', '')
              })
              .catch(() => {
                // this.$store.commit('qrcode', '')
              })
          }
        },
        (error) => {
          this.$loading(false)
          let errstr = ""
          if (error.includes("is offline") || error.includes("离线")) {
            errstr = this.$t("home.offline", { devicecode: this.qrcode })
          } else if (error.includes("Permission") || error.includes("权限")) {
            errstr = this.$t("home.permissionfail")
          } else if (error.includes("not bound") || error.includes("未绑定")) {
            errstr = this.$t("home.unbound", { devicecode: this.qrcode })
          } else if (error.includes("No battery") || error.includes("可用")) {
            errstr = this.$t("home.nobattery", { devicecode: this.qrcode })
          } else {
            errstr = error
          }
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      )
    },
    loadUser(isOnScan = false, call) {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.loginOptionShow = false
            this.getPayMode()
            if (isOnScan && this.qrcode) {
              this.onScan()
            }
            call && call(resp)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.userinfo_fail,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
          console.log(error)
        }
      )
    },
    onNeoPay() {
      this.$loading(true)
      this.$post(
        `${NEOPAY_RECHARGE}`,
        {
          paymentId: this.payMode.pId,
          amount: this.cabinet.yajin,
          rechargeType: "balance",
          qrcode: this.qrcode,
          rentNow: true,
          saveCard: false
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.paymentUrl) {
            window.location.href = resp.data.paymentUrl
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    getStripeToken() {
      console.log("getStripeToken-", this.cabinet)
      getToken(this.cabinet && this.cabinet.yajin, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet) {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.payMode.pId // 代理或平台支付配置id
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onCreditcard() {
      this.$loading(true)
      this.$post(
        `${STRIPE_PREAUTH_OF_CONFIRM}/${this.qrcode}`,
        {
          paymentId: this.payMode.pId,
          amount: this.cabinet.yajin
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: {
                  type: "tabs"
                }
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nConfirm.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard(this.payMode.publicKey)
          })
          console.log(error)
        }
      )
    },
    getCouponCode() {
      if (!this.cabinet) {
        return this.$toast(this.$t("home.inputcodetitle"))
      }
      this.$loading(true)
      this.$get(
        `${COUPON_SEND}?code=${this.couponCode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.onPay()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.onPay()
        }
      )
    },
    getPayMode() {
      this.$loading(true)
      const qrcode = (this.cabinet && this.cabinet.cabinetID) || ""
      this.$get(
        `${PAYMODE}?id=${qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (
                v.pPaymentType === "STRIPE_CARD" ||
                v.pPaymentType === "NeoPay"
              ) {
                v.label = "Credit Card"
                v.phone = "all"
                // } else if (v.pPaymentType === "STRIPE_WECHAT") {
                //   v.label = "WeChat"
                //   v.phone = "wechat"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              }
            })
            this.payModeList = list
            if (list.length > 0) {
              this.payMode = list[0]
              if (list[0].pPaymentType === "STRIPE_CARD") {
                this.loadStripeScript(list[0].publicKey)
              }
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_paymod,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log("PAYMODE", error)
        }
      )
    },
    onLoadElement() {
      if (window.stripeElements) {
        // this.loadStripeScript(this.payMode.publicKey)
        this.creditcard = window.stripeElements.create("card", {
          style: this.cardStyle
        })
        this.creditcard.mount("#credit-card-element")
        this.$nextTick(() => {
          this.isCreditcard = true
        })
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      window.stripe.confirmPayment({
        elements: vm.creditcard,
        confirmParams: {
          return_url: window.location.href
        }
      })
      // paymentIntent通过付款方式确认卡设置
    },
    confirmSetup(id) {
      this.$loading(true)
      this.$get(
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`,
        {},
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          this.$router.replace(
            `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onLogin(code) {
      this.$loading(true)
      this.$post(
        LOGIN_CODE,
        {
          mobile: this.areaCode + this.phone,
          verifyCode: code
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.onCode()
            this.loginCodeShow = false
            this.loginOptionShow = false
            this.$store.commit("loginMode", "phone")
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        }
      )
    },
    onSendCode() {
      if (!this.phone) {
        this.$toast(this.i18nMsg.msg_fail_phone)
        return
      }
      // if (!this.code) {
      //   this.$toast(this.i18nMsg.msg_fail_code)
      //   return
      // }
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, "8")
      let mdsign = md5(str + "GZzbjkj")
      // console.log(timp, str, mdsign, this.phone)
      this.$loading(true)
      this.$post(
        SEND_CODE,
        {
          mobile: this.areaCode + this.phone,
          timestamp: timp,
          sign: mdsign,
          useDebug: "true"
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          this.loginCodeShow = true
          // this.onLogin('1234')
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onCode() {
      console.log(this.qrcode)
      if (this.token) {
        this.loadUser()
      }
      if ((this.qrcode || this.$route.query.qrcode) && this.token) {
        this.onScan()
      }
    },
    onDownload() {
      let downloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        downloadUrl = process.env.VUE_APP_APP_STORE
      }
      if (downloadUrl) {
        window.open(downloadUrl)
      } else {
        this.$toast(this.i18nMsg.appoffline)
      }
    },
    onAppStore() {
      console.log("11111")
      const Url = process.env.VUE_APP_APP_STORE
      if (!Url) return this.$toast(this.i18nMsg.appoffline)
      window.open(Url)
    },
    onGooglePlay() {
      console.log("22222")
      const Url = process.env.VUE_APP_GOOGLE_PLAY
      if (!Url) return this.$toast(this.i18nMsg.appoffline)
      window.open(Url)
    }
  },
  created() {
    let qrCode = this.qrcode || this.$route.query.qrcode
    console.log("qrCode====", qrCode)
    // this.onDownload()
    if (qrCode) {
      this.$store.commit("qrcode", qrCode)
      if (this.user) {
        this.onCode()
      }
    }

    const payId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    )
    if (payId) {
      this.confirmSetup(payId)
    }

    if (!this.user || !this.cabinet) {
      this.payModeList = this.payModeDefaultList
    }
    // this.onCode()
    // this.onCaptcha()
  },
  mounted() {
    const { query } = this.$route
    const loginToken = this.appleIdToken || query.AppleIdToken
    if (loginToken && !this.user) {
      this.loginByApple(loginToken, () => {
        this.loadUser("", (res) => {
          const params = { ...query, AppleIdToken: "" }
          const urlParams = new URLSearchParams(params).toString()
          this.$router.replace(`/loginborrow?${urlParams}`)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.buttonDiv {
  position: relative;
  z-index: 99;
  top: 0;
  opacity: 0;
  height: 0 !important;
}

.googlewarp {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.select {
  color: #000 !important;
  background-color: #e0e722 !important;
  border: none !important;
}

.selectd {
  color: #000;
  border: solid 1px #e0e722 !important;
}

.mb-8 {
  margin-bottom: 60px;
}

.minheight-5 {
  min-height: 90px;
}

.agreen {
  width: 80%;
  color: #6e6e6e;
  text-align: center;
  margin: 40px auto 20px;
  font-size: small;

  a {
    text-decoration: none;
    color: #202122;
    font-weight: 600;
  }
}

.loginbtn {
  border: solid 1px #ebebeb;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  background: none;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.addcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  color: #757575;

  // box-shadow: 3px 6px 10px #e8e8e8;
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-right: 20px;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.home-body {
  background-color: #fff;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  overflow: hidden;
  padding: 40px;
  box-sizing: border-box;
  height: 100%;

  .title {
    font-family: "Montserrat-SemiBold";
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    white-space: nowrap;
    margin: 0 0 50px;
  }

  .options {
    width: 60%;
    margin: 10px auto 0;
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    border-radius: 20px;
    padding: 20px 0;
    background: none;
  }

  .logoutoptions {
    width: 100%;
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    margin: 40px 0;
    background: none;
  }

  .normaltitle {
    font-family: "Montserrat-Medium";
    font-size: 28px;
    color: #171917;
    margin: 30px 0 32px;
  }

  .paymethod {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .paymethod-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 45px;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin-bottom: 24px;

    img {
      width: 45px;
      height: 40px;
      margin-right: 10px;
    }

    span {
      // color: #000;
      font-size: 28px;
      white-space: nowrap;
    }
  }

  .placeinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    padding: 28px 20px;
    margin: 40px 0 0;

    .preicon {
      width: 38px;
      height: 38px;
      margin-right: 20px;
    }

    .afticon {
      width: 34px;
      height: 34px;
      margin-left: 20px;
    }

    .cvc {
      font-size: 28px;
      color: #c8c8c8;
    }

    input {
      flex: 1;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
    }
  }

  .shopdetail {
    border: dashed 4px #000;
    border-radius: 20px;
    padding: 0 10px;
    margin: 50px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & > img {
      width: 78px;
      // height: 142px;
      // position: absolute;
      // left: 28px;
      // top: -12px;
    }

    .centermsg {
      // margin-right: 16px;
      p {
        margin: 10px 0;
      }

      .top {
        font-size: 28px;
        color: #171917;
      }

      .bottom {
        color: #666666;
        font-size: 24px;
      }
    }

    .pricing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-left: 40px;
      color: #000;

      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
      }
    }
  }

  .rentbtn {
    width: 100%;
    background: #e0e722;
    border-radius: 20px;
    color: #000;
    text-align: center;
    padding: 28px 0;
    font-family: "Montserrat-SemiBold";
    font-size: 36px;
    font-weight: 500;
    max-height: 108px;
    margin: 40px 0 0;
  }

  .mt-4 {
    margin-top: 40px;
  }
}
.apple-login-btn {
  margin-bottom: 40px;
  border: solid 5px #dadce0;
}
.facebookbtn {
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;
  margin-bottom: 40px;
  font-size: 28px;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;

  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px;
  }

  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto 40px;
  }

  .loginoptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 0;

    &-item {
      border: solid 1px #ebebeb;
      border-radius: 20px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 25px 0 0;

      img {
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .phonelogin {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      transform: rotateZ(180deg);
    }

    input {
      flex: 1;
      font-size: medium;
      border: none;
      outline: none;
    }

    .captcha {
      width: 180px;
    }

    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }

  .facebookbtn {
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .goolebtn {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #ebebeb;
    color: #000;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}

.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}

.creditcard-btn {
  margin: 48px;
}

.addsign {
  float: left;
  background-color: #fff;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px;
  width: 18%;
  height: 100%;
  border-radius: 20px;
  margin: 33px 0 20px 20px;

  span {
    display: block;
    background-color: #dddddd;
    color: #fff;
    font-size: 85px;
    text-align: center;
    border-radius: 20px;
  }
}

.scroll-x {
  float: right;
  width: 70%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
}

.popup-card-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px 20px;
  margin: 20px 20px 20px 0;

  p {
    margin: 0;
  }

  .value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: #00904a;
    font-weight: 500;

    .pBrand {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  .deleteicon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
</style>
